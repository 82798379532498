/**
 * Important Notice:
 *
 * If Scripts like select2, owlCarousel or fancybox are needed,
 * the have to be moved from libs_notUsed to libs. Actually only
 * the flexnav files are in the folder libs.
 */

jQuery(function ($) {
    // Fix missing ID issue when wpcf7 renders invalid input element
    $('.fieldWrapper .wpcf7-not-valid').each(function () {
        if ($(this).attr('id') === undefined && $(this).parent().prev().attr('for') !== undefined) {
            $(this).attr('id', $(this).parent().prev().attr('for'))
        }
    })

    /* recruiting form */
    $('input[type=file]').on('change', function () {
        var filename = $(this).val().split('\\').pop();
        if (filename)
            $(this).parent().parent().find('label').html(filename).addClass('selected');
        else
            $(this).parent().parent().find('label').html('').removeClass('selected');
    });

    /* label animation */
    $(".fieldWrapper input[type='text'], .fieldWrapper input[type='email'], .fieldWrapper textarea").closest('.fieldWrapper').addClass('moveable');

    $(".fieldWrapper input[type='text'], .fieldWrapper input[type='email'], .fieldWrapper textarea").on('blur', function () {
        if (!$(this).val()) {
            $(this).closest('.fieldWrapper').removeClass('active');
        }
    }).on('focus', function () {
        $(this).closest('.fieldWrapper').addClass('active');
    });

    /* enable select2 for select fields */
    $('select').select2({
        minimumResultsForSearch: Infinity,
        width: "style"
    });
});
