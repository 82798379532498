/*
  FlexNav.js 1.3.3
  Created by Jason Weaver http://jasonweaver.name
  Released under http://unlicense.org/
*/


(function ($) {

    $.fn.flexNav = function (options) {

        var $nav, breakpoint;

        $nav = $(this);
        $nav.addClass('opacity');

        $nav.find("li").each(function () {
            if ($(this).has("ul").length) {
                return $(this).addClass("item-with-ul").find("ul").hide();
            }
        });

        if ($nav.data('breakpoint')) {
            breakpoint = $nav.data('breakpoint');
        }

        resizer = function () {
            var selector;
            if ($(window).width() <= breakpoint) {
                $nav.removeClass("lg-screen").addClass("sm-screen");
                $('.menu-button').parent().parent().removeClass('desktopNav');
            } else if ($(window).width() > breakpoint) {
                $nav.removeClass("sm-screen").addClass("lg-screen");
                $nav.removeClass('flexnav-show');
                $('.menu-button').parent().parent().addClass('desktopNav');
                $('.item-with-ul').find('ul.flexnav-show').each(function () {
                    resetMenu.call($(this).parent().get(0));
                });
            }
        };

        resetMenu = function () {
            if ($nav.hasClass('lg-screen') === true && $(this).find('>ul').hasClass('flexnav-show') === true) {
                return $(this).find('>ul').removeClass('flexnav-show').stop(true, true).animate({
                    height: ["hide", "swing"],
                    opacity: "hide"
                }, 250);
            }
        };

        $('.menu-button').on('click', function (e) {
            var $btnParent, $thisNav, bs;
            $('.menu-button').toggleClass('active');
            e.preventDefault();
            e.stopPropagation();
            bs = $('.menu-button');
            $btnParent = $(this).is(bs) ? $(this) : $(this).parent(bs);
            $thisNav = $btnParent.data('navEl');
            $('.top').toggleClass('flexnav-open');
            return $thisNav.toggleClass('flexnav-show');
        });

        $('.menu-button').data('navEl', $nav);

        $('.item-with-ul a').on('click', function (e) {

            var $sub = $(this).parent('.item-with-ul').find('>ul');

            if ($nav.hasClass('lg-screen') === true) {
                $(this).parent('.item-with-ul').siblings().find('ul.flexnav-show').removeClass('flexnav-show').hide();
                $('.item-with-ul').removeClass('active');
            }

            if ($sub.hasClass('flexnav-show') === true) {
                //$sub.removeClass('flexnav-show').slideUp(250);
                $sub.removeClass('flexnav-show').hide();
                $(this).parent().removeClass('active');
            } else if ($sub.hasClass('flexnav-show') === false) {
                //$sub.addClass('flexnav-show').slideDown(250);
                $sub.addClass('flexnav-show').show();
                $(this).parent().addClass('active');
            }

        });

        resizer();
        return $(window).on('resize', resizer);

    };

})(jQuery);
